window.onload = function() {
  var videoElement = document.getElementById('first-screen-video');
  if (videoElement) {
    var source = document.createElement('source');
    if (window.innerWidth <= 450) {
      source.src = '/assets/home/first-screen-video-new-mobile.mp4';
    } else {
      source.src = '/assets/home/first-screen-video-new.mp4';
    }
    source.type = 'video/mp4';
    videoElement.appendChild(source);
    videoElement.load();
    videoElement.addEventListener('loadeddata', function () {
      videoElement.play().catch(function (error) {
          console.error('Autoplay failed:', error);
          var attemptToPlay = setInterval(function () {
              videoElement.play().then(function () {
                  clearInterval(attemptToPlay);
              }).catch(function (err) {
                  console.error('Retry failed:', err);
              });
          }, 500);
      });
    });
  }
};
