import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Thumbs, EffectFade } from 'swiper/modules';
import 'swiper/css';

document.addEventListener("turbo:load", function(){
  var swiperr = Swiper;
  var init = false;

  function swiperMode() {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 650px)');
    if (mobile.matches) {
      init = false;
    } else {
      var swiper1 = new Swiper(".main-slider", {
        modules: [Autoplay, Pagination],
        loop: true,
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 30,
        loopFillGroupBlank: false,
        speed: 2500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        breakpoints: {
          900: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          750: {
            slidesPerView: 3,
            slidesPerGroup: 2,
          },
          650: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          }
        }
      })

      var swiper2 = new Swiper(".celebrities", {
        modules: [Autoplay, Pagination],
        loop: true,
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 30,
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3500,
        },
        breakpoints: {
          850: {
            slidesPerView: 3,
            slidesPerGroup: 2,
          },
        }
      });

      const sliderBox = document.querySelector('#complete-look-slider');
      if (sliderBox) {
        const slidesItems = sliderBox.querySelectorAll('.product-item');
        const slideCount = slidesItems.length;

        if (slideCount <= 3) {
          sliderBox.style.justifyContent = "center"
        }
      }

      const sliderBox2 = document.querySelector('#may-also-like-wrapper');
      if (sliderBox2) {
        const slidesItems2 = sliderBox2.querySelectorAll('.product-item');
        const slideCount2 = slidesItems2.length;

        if (slideCount2 <= 3) {
          sliderBox2.style.justifyContent = "center"
        }
      }
    }
  }

  var swiper6 = new Swiper(".instagram-slider", {
    modules: [Autoplay, Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 16,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    breakpoints: {
      420: {
        slidesPerView: 2,
      },
      650: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }
  });

  var swiper7 = new Swiper(".wishlist-slider", {
    modules: [Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 16,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      380: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 3,
      }
    }
  });

  window.addEventListener('load', function() {
    swiperMode();
  });

  window.addEventListener('resize', function() {
    swiperMode();
  });

});

document.addEventListener("turbo:frame-render", function(){
  var swiper10 = new Swiper(".celebrities-brides-slider", {
    modules: [Navigation],
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 12,
    navigation: {
      nextEl: ".next-img-show",
      prevEl: ".prev-img-show",
    },
  });
})

var swiper8 = new Swiper(".left-box-gallery", {
  modules: [Navigation],
  slidesPerView: 3,
  slidesPerGroup: 1,
  spaceBetween: 12,
  navigation: {
    nextEl: ".next-img",
    prevEl: ".prev-img",
  },
  breakpoints: {
    431: {
      direction: "vertical",
    }
  }
});

var swiper9 = new Swiper(".left-box-show-image", {
  modules: [ Thumbs, EffectFade, Navigation ],
  effect: "fade",
  thumbs: {
    swiper: swiper8,
  },
  navigation: {
    nextEl: ".next-img-show",
    prevEl: ".prev-img-show",
  },
});

var swiper11 = new Swiper(".typical-section-slider-image", {
  modules: [ EffectFade ],
  loop: true,
  slidesPerView: 1,
  effect: "fade",
});

var swiper10 = new Swiper(".typical-section-slider", {
  modules: [ Autoplay, Pagination, Thumbs ],
  loop: true,
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 5500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: swiper11,
  },
});

var swiper10 = new Swiper(".published", {
  modules: [ Autoplay ],
  loop: true,
  slidesPerGroup: 1,
  slidesPerView: 2,
  spaceBetween: 25,
  speed: 500,
  autoplay: {
    delay: 3500,
    enabled: true,
    disableOnInteraction: false,
  },
  breakpoints: {
    650: {
      slidesPerView: 3,
    },
    800: {
      slidesPerView: 4,
    },
    1000: {
      slidesPerView: 5,
    }
  }
});
if (window.innerWidth < 570) {
  const marquee = document.querySelector('.marquee');
  if (marquee) {
    let offset = 0;
    const speed = 1;
    function moveMarquee() {
      offset -= speed;
      if (Math.abs(offset) >= marquee.firstElementChild.offsetWidth + 40) {
        const firstItem = marquee.firstElementChild;
        offset += firstItem.offsetWidth + 40;
        marquee.appendChild(firstItem);
      }
      marquee.style.transform = `translateX(${offset}px)`;
      requestAnimationFrame(moveMarquee);
    }
    moveMarquee();
  }
}
